"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterNullsAndPadEmpty = exports.isNonNullish = void 0;
// Type predicate checking whether x is null or undefined.
function isNonNullish(x) {
    return x !== null && x !== undefined;
}
exports.isNonNullish = isNonNullish;
// Removes nulls from array.  If array is empty afterward, add a single null element.
function filterNullsAndPadEmpty(array) {
    if (!isNonNullish(array)) {
        return [null];
    }
    const filteredArray = array.filter(isNonNullish);
    if (filteredArray.length === 0) {
        return [null];
    }
    return filteredArray;
}
exports.filterNullsAndPadEmpty = filterNullsAndPadEmpty;
