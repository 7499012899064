"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processAllSettledResults = void 0;
// Loops through a Promise.allSettled array and throws a single conglomerated error of failures (if they exist)
function processAllSettledResults(results, callsite) {
    function justRejected(entry) {
        return entry.status === 'rejected';
    }
    const failures = results.filter(justRejected);
    // Useful logging but too verbose for production
    // const successes = results.filter((res) => res.status === 'fulfilled')
    // console.log('successful results:')
    // for (const promise of successes) {
    //   console.log((promise as PromiseFulfilledResult<any>).value)
    // }
    if (failures.length > 0) {
        const failureMsgArray = failures.map((entry) => {
            try {
                return entry.reason.toString();
            }
            catch (e) {
                return '';
            }
        });
        const msg = 'Encountered failures in ' +
            callsite +
            ':\n[' +
            failureMsgArray.join('\n') +
            ']\n';
        throw new Error(msg);
    }
}
exports.processAllSettledResults = processAllSettledResults;
