"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUSWestCoastDate = void 0;
// Returns a date object with:
// Year, Month, Day set to the corresponding year, month, and day PDT of the provided date.
// To access these values without accidentally reinterpreting them into your local timezone,
// you should use getUSWestCoastDate(...).getUTC[Date|Month|Year](), NOT .get[Date|Month|Year]!
// Midnight UTC i.e. 0 hours/minutes/...
// This is useful for reasoning about the current *date* on the west coast
function getUSWestCoastDate(now) {
    const westCoastDate = new Date(now);
    // Roll back the date if needed.  PDT is 7 hours behind UTC.
    westCoastDate.setHours(westCoastDate.getHours() - 7);
    // Wipe all other time information
    return new Date(Date.UTC(westCoastDate.getUTCFullYear(), westCoastDate.getUTCMonth(), westCoastDate.getUTCDate()));
}
exports.getUSWestCoastDate = getUSWestCoastDate;
