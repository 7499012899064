"use strict";
// Generates a type from Type that matches type except recursively omits FieldName from its properties (and the
// properties of its properties, etc).  Does not remove FieldName from primitive types, array types, or types contained
// by arrays (i.e. from T in Array<T>).
//
// Borrowed liberally from https://stackoverflow.com/questions/55539387/deep-omit-with-typescript
//
// Example:  (comment in and see if you get compilation errors to test!)
//
// type Test = {
//   foo: number
//   readonly bar?: { foo: number, bam: string }
//   arr: Array<{foo: number, bar: string}>
//   big: BigInt | null
// }
Object.defineProperty(exports, "__esModule", { value: true });
