"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRandomInteger = void 0;
// Returns a random integer, in [min, max] (inclusive of both min and max).
// min and max must both be integers, otherwise this returns null.
function generateRandomInteger(min, max) {
    if (Number.isInteger(min) && Number.isInteger(max)) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return null;
}
exports.generateRandomInteger = generateRandomInteger;
