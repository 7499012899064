"use strict";
// Generic Batch Processing Types.  This structure allows us to process a set of related types by registering
// a handler for each one.  For example, we can operate over all Accounts or Tasks generically.
Object.defineProperty(exports, "__esModule", { value: true });
exports.collapseProcessingResultsIntoArray = void 0;
// It may be more useful to process a HandlerResultContainer as a single array.  This function collapses
// handlerResultsMap into the most specific type possible (which may be a very big union if you do not declare anything/
// intentionally register handlers with different return types).
// No particular order of results is guaranteed.
function collapseProcessingResultsIntoArray(handlerResults) {
    const ret = [];
    for (const fieldName in handlerResults) {
        ret.push(...handlerResults[fieldName]);
    }
    return ret;
}
exports.collapseProcessingResultsIntoArray = collapseProcessingResultsIntoArray;
// ========= PROCESSOR INSTANTIATION EXAMPLE ============
// Processor functions need to be defined for each Processor<T> type. We provide an example here.
// It seems like we should be able to make this generic by iterating over keys, but it doesn't work:
// 1) Typescript conservatively assumes e.g. input[a] is the union of all the possible types, and we
// cannot templatize a getter function with a *value* (unlike in cpp where get<4>() would be valid).  That is, we
// can't declare that input[a] and handler[a] have matching typing, even though the types are connected via the T type
// in Processor T.
//
// Even if we have to instantiate this for every keyspace, it seems like we'd be able to generically templatives *that*
// implementation with something like:
//
// function helper<T extends ProcessorInputTypes, MapT extends HandlerMap<T>> (input: InputContainer<T>, handlerMap: MapT):
//    HandlerResultContainer<T, HandlerMap<T>> {
//    const ret: HandlerResultContainer<T, MapT> = ...
// }
// ... but even this doesn't work- somehow all the key types are calculated as type never[], which is not helpful.
// Maybe this is related to (see https://github.com/microsoft/TypeScript/issues/30650#issuecomment-486680485)?
//
// Instead of getting even fancier, we just manually connect the handlers to the tasks.  Unfortunately
// this means that you must copy and update this function for each processor you instantiate, even if the processors
// share the same keyspace (but have different types)
//
// EXAMPLE:
//
// // Runs the handler in handlerMap on each element of the relevant array in input and
// // returns the results of all the handlers, in the same order the tasks were input.
// // NOTE that handlers are not await'ed i.e. this function is synchronous.  If the caller wants to await, it is up to
// // them to orchestrate it.  This matches array.map() semantics.
// // WARNING: THROWING AN ERROR WILL SHORT CIRCUIT THE PROCESSING JOB! IF YOU WISH TO ENSURE ALL TASKS ARE RUN, YOUR
// // HANDLERS SHOULD INSTEAD RETURN A REJECTED PROMISE.
// export const processAllFinancialTasks: Processor<FinancialTaskTypes> = (input, handlerMap) => {
//   const ret: ReturnType<Processor<FinancialTaskTypes>> = {
//     PAY_CRITICAL_EXPENSES: [],
//     BUILD_ONE_MONTH_EMERGENCY_FUND: [],
//     EXTEND_EMERGENCY_FUND_TO_THREE_MONTHS: [],
//     EXTEND_EMERGENCY_FUND_TO_SIX_MONTHS: [],
//     INVEST_IN_BROKERAGE_ACCOUNT: [],
//     PAY_OFF_DEBT_TASK: [],
//     CONTRIBUTE_TO_401K_2022: [],
//     CONTRIBUTE_TO_IRA_2022: []
//   }
//   type KeyTypes =  keyof FinancialTaskTypes
//   {
//     const taskType: KeyTypes = 'PAY_CRITICAL_EXPENSES'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'BUILD_ONE_MONTH_EMERGENCY_FUND'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'EXTEND_EMERGENCY_FUND_TO_THREE_MONTHS'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'EXTEND_EMERGENCY_FUND_TO_SIX_MONTHS'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'INVEST_IN_BROKERAGE_ACCOUNT'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'PAY_OFF_DEBT_TASK'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'CONTRIBUTE_TO_401K_2022'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   {
//     const taskType: KeyTypes = 'CONTRIBUTE_TO_IRA_2022'
//     const handler = handlerMap[taskType]
//     ret[taskType] = input[taskType].map(handler)
//   }
//   return ret
// }
