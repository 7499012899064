"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exhaustiveSwitchGuard = void 0;
// Using this function in a default statement of a switch statement ensures your switch statement covers all possible
// cases.
// Example:
// enum E {
//  ONE
//  TWO
//  THREE
// }
//
// // This will fail at compile time :)
// switch(e: E) {
//   case E.ONE:
//     console.log('ONE')
//     break
//   default:
//     exhaustiveSwitchGuard(e)
// }
//
// // This will succeed at compile time :)
// switch (e) {
//   case E.ONE:
//     console.log('ONE')
//     break
//   case E.TWO:
//     console.log('TWO')
//     break
//   case E.THREE:
//     console.log('THREE')
//     break
//   default:
//     exhaustiveSwitchGuard(e)
// }
//
// Taken from https://medium.com/technogise/type-safe-and-exhaustive-switch-statements-aka-pattern-matching-in-typescript-e3febd433a7a
function exhaustiveSwitchGuard(_) {
    throw new Error('exhaustiveSwitchGuard should never execute');
}
exports.exhaustiveSwitchGuard = exhaustiveSwitchGuard;
